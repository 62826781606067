<style scoped lang="less">
  .map-show {
    display: flex;
    flex-direction: column;
  }
  .results {
    height: 100px;
    overflow: auto;
  }
  .item {
    display: flex;
    align-items: flex-start;
    padding-top: 10px;
  }
  .item.active {
    .title, .address {
      color: #2d8cf0;
    }
  }
  .item + .item {
    border-top: 1px solid #EEE;
    margin-top: 10px;
  }
  .item .info {
    flex: 1;
  }
  .title, .address {
    transition: all .3s;
    cursor: pointer;
  }
  .title:hover, .address:hover {
    color: #2d8cf0;
  }
  .title {
    margin-right: 10px;
  }
  .tag + .tag {
    margin-left: 5px;
  }
  .tag {
    padding: 1px 5px;
    font-size: 12px;
    border-radius: 3px;
    border: 1px solid #2d8cf0;
    background-color: #2d8cf0;
    color: #FFF;
  }
</style>

<template>
  <div class="map-show" v-loadingx="loading.load"></div>
</template>

<script>
import { loadBMapScript, setBoundary, getPointIcon, getCurrentLocation } from '@/components/map/lib'

export default {
  props: {
    marked: { type: Array, default: () => [] }
  },
  data () {
    return {
      loading: {
        load: false
      }
    }
  },
  watch: {
    marked: {
      deep: true,
      handler () {
        this.$map && this.loadMarked()
      }
    }
  },
  methods: {
    loadDistrictLayer (name, kind) {
      this.$districtLayer && this.$map.removeDistrictLayer(this.$districtLayer)
      this.$districtLayer = new window.BMapGL.DistrictLayer({
        name: '(' + name + ')',
        kind: kind,
        fillColor: '#FFF',
        fillOpacity: 0,
        strokeColor: '#999',
        strokeWeight: 1,
        strokeOpacity: 0.2,
        strokeStyle: 'dashed'
      })
      this.$map.addDistrictLayer(this.$districtLayer)
    },
    async loadMap () {
      this.loading.load = true
      await loadBMapScript('webgl', '1.0', 't7IKgQ2U43nAPSFj6lGyob7pDDqnXrMf', new Date().getTime())
      const cureentLocation = await getCurrentLocation()
      new window.BMapGL.Geocoder().getLocation(cureentLocation, async ({ address, addressComponents, point }) => {
        console.log(address, addressComponents)
        this.$map = new window.BMapGL.Map(this.$el)
        this.$map.centerAndZoom(point, 10)
        this.$map.enableScrollWheelZoom(true)

        setBoundary({
          map: this.$map,
          address: addressComponents.province + addressComponents.city,
          maskStyle: {
            isBuildingMask: true,
            isPoiMask: true,
            isMapMask: true,
            showRegion: 'inside',
            topFillColor: '#2b85e4',
            topFillOpacity: 0.1,
            sideFillColor: '#2b85e4',
            sideFillOpacity: 0.1
          },
          borderStyle: {
            strokeColor: '#2b85e4',
            strokeWeight: 1,
            strokeOpacity: 0.2,
            strokeStyle: 'dashed',
            enableClicking: false
          }
        })

        this.$viewport = await setBoundary({
          map: this.$map,
          address: addressComponents.province + addressComponents.city + addressComponents.district,
          polygonStyle: {
            fillColor: '#2b85e4',
            fillOpacity: 0.05,
            strokeColor: '#2b85e4',
            strokeWeight: 1,
            strokeOpacity: 1,
            strokeStyle: 'solid',
            enableClicking: false
          }
        })

        this.loadMarked(false)
        this.$nextTick(() => {
          this.$map.setViewport(this.$viewport, {
            margins: [10, 10, 10, 10]
          })
        })

        this.loading.load = false

        this.loadDistrictLayer(addressComponents.province + addressComponents.city, 2)
      })
    },
    loadMarked (setViewport = true) {
      if (!this.$defaultMarker) {
        this.$defaultMarker = []
      }
      if (this.$defaultMarker.length) {
        this.$defaultMarker.forEach(v => {
          this.$map.removeOverlay(v)
        })
      }
      let points = []
      this.marked.forEach(item => {
        const point = new window.BMapGL.Point(item.point.lng, item.point.lat)
        points.push(point)
        const marker = new window.BMapGL.Marker(point, {
          title: item.title || '坐标点',
          icon: getPointIcon('red')
        })
        if (item.address) {
          marker.addEventListener('click', () => {
            this.$map.openInfoWindow(new window.BMapGL.InfoWindow(item.address, {
              title: item.title
            }), item.point)
          })
        }
        this.$map.addOverlay(marker)
        this.$defaultMarker.push(marker)
      })
      if (setViewport) {
        if (points.length > 1) {
          this.$map.setViewport(points, {
            margins: [50, 50, 50, 50]
          })
        } else if (points.length === 1) {
          this.$map.flyTo(points[0], 15)
        } else {
          this.$map.setViewport(this.$viewport, {
            margins: [10, 10, 10, 10]
          })
        }
      }
    }
  },
  mounted () {
    this.$nextTick(this.loadMap)
  },
  destroyed () {
    this.$defaultMarker = null
    this.$map = null
  }
}
</script>
