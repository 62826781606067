<style scoped lang="less">
  .activity-show {
    background-color: #F8F8F8;
    display: flex;
    flex: 1;
    padding: 10px;
    height: 100%;
    box-sizing: border-box;
  }
  .map, .data {
    background-color: #FFF;
  }
  .map {
    flex: 1;
    margin-right: 10px;
  }
  .data {
    padding: 5px;
    box-sizing: border-box;
    width: 40vw;
    display: flex;
    flex-direction: column;
    .list {
      align-items: flex-start;
      align-content: flex-start;
      flex: 1;
      height: 0;
      display: flex;
      flex-wrap: wrap;
      .wrap {
        display: flex;
        width: 33.33%;
        padding: 5px;
        box-sizing: border-box;
        .item {
          flex: 1;
          padding: 10px;
          border: 1px solid #EEE;
          border-radius: 5px;
          cursor: pointer;
          transition: all .3s;
          &:hover {
            background-color: #F8F8F8;
          }
        }
      }
    }
  }
</style>

<template>
  <div class="activity-show">
    <map-marked style="flex: 1;" :marked="marked" />
    <div class="data">
      <template v-if="!chooseData">
        <div style="display: flex;align-items: center;">
          <span>日期：</span>
          <DatePicker v-model="year" type="year" placement="bottom-end" placeholder="请选择日期" style="width: 200px" />
          <fm-btn style="margin-left: 10px;" @click="onSearch">查询</fm-btn>
        </div>
        <div class="list">
          <div v-for="(item, index) in dataList" :key="item.id + '-' + index" class="wrap">
            <div @click="show(item)" class="item">
              <div>{{item.activityType ? item.activityType.name : '其他'}}</div>
              <div>{{item.name || '-'}}</div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div style="display: flex;align-items: center;">
          <fm-btn @click="showAll">
            <Icon size="20" type="ios-arrow-back" style="cursor: pointer;" />
            返回
          </fm-btn>
          【{{chooseData.activityType ? chooseData.activityType.name : '其他'}}】
          <h4 style="margin: 0 10px;">{{chooseData.name || '-'}}</h4>
        </div>
        <div style="margin-top: 10px; border-top: 1px solid #EEE;flex: 1;height: 0;overflow: auto;">
          <div style="color: #657180;padding: 10px 0;">活动时间：{{chooseData.startDate}} - {{chooseData.endDate}}</div>
          <pre style="margin: 0; white-space: pre-wrap;">{{chooseData.content}}</pre>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { activityRequest, activityTypeRequest } from '../../api'
import MapMarked from '@/components/map/marked'

export default {
  components: { MapMarked },
  data () {
    return {
      dataList: [],
      activityTypeList: [],
      marked: [],
      year: new Date(),
      chooseData: null
    }
  },
  computed: {
    daterange () {
      return {
        shortcuts: [
          {
            text: '近一周',
            value () {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              return [start, end];
            }
          },
          {
            text: '近一月',
            value () {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              return [start, end];
            }
          },
          {
            text: '近三月',
            value () {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              return [start, end];
            }
          }
        ]
      }
    }
  },
  methods: {
    showAll () {
      this.chooseData = null
      this.marked = this.dataList.filter(v => v.lng && v.lat).map(item => {
        return {
          title: item.title, address: item.address, point: {
            lng: item.lng, lat: item.lat
          }
        }
      })
    },
    show (item) {
      this.chooseData = item
      if (item.lng && item.lat) {
        this.marked = [
          {
            title: item.title, address: item.address, point: {
              lng: item.lng, lat: item.lat
            }
          }
        ]
      } else {
        this.marked = []
        this.$notice.warning('暂未设置坐标，无法查看')
      }
    },
    onSearch () {
      this.loadData()
    },
    async loadTypes () {
      this.activityTypeList = await activityTypeRequest.get()
    },
    async loadData () {
      this.dataList = []
      if (this.$authFunsProxy.get) {
        this.dataList = await activityRequest.get({ year: this.year.getFullYear() })
      } else if (this.$authFunsProxy.getMy) {
        this.dataList = await activityRequest.getByAuth({ year: this.year.getFullYear() })
      }
      this.showAll()
    }
  },
  mounted () {
    this.loadTypes()
    this.loadData()
  }
}
</script>
