import { render, staticRenderFns } from "./show.vue?vue&type=template&id=08984aea&scoped=true&"
import script from "./show.vue?vue&type=script&lang=js&"
export * from "./show.vue?vue&type=script&lang=js&"
import style0 from "./show.vue?vue&type=style&index=0&id=08984aea&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../yiweb/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08984aea",
  null
  
)

export default component.exports