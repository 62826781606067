import sysEnv from '@/env/env'

export function loadScript(url) {
  return new Promise(resolve => {
    let script = document.createElement("script");
    script.onload = resolve;
    script.src = url;
    document.body.appendChild(script);
  })
}

export async function loadBMapScript(type, version, ak, time) {
  window.BMap_loadScriptTime = (new Date).getTime();
  window.BMAP_PROTOCOL = String(location.protocol).replace(/[^\w]/, '');
  window.BMapGL = window.BMapGL || {};
  let link = document.createElement('link');
  link.setAttribute('rel', 'stylesheet');
  link.setAttribute('type', 'text/css');
  link.setAttribute('href', 'https://api.map.baidu.com/res/webgl/10/bmap.css');
  document.getElementsByTagName('head')[0].appendChild(link);
  await loadScript(`${location.protocol}//api.map.baidu.com/getscript?type=${type}&v=${version}&ak=${ak}&services=&t=${time}`);
  return Promise.resolve();
}

export function setBoundary ({ map, address, maskStyle, polylineStyle, polygonStyle }) {
  return new Promise(resolve => {
    new window.BMapGL.Boundary().get(address, function (rs) {
      let path = []
      for (let i = 0; i < rs.boundaries.length; i++) {
        let xyArr = rs.boundaries[i].split(';')
        let ptArr = [];
        for (let j = 0; j < xyArr.length; j++) {
          let tmp = xyArr[j].split(',')
          let pt = new window.BMapGL.Point(tmp[0], tmp[1])
          ptArr.push(pt)
          path.push(pt)
        }
        if (maskStyle) {
          let mapmask = new window.BMapGL.MapMask(ptArr, maskStyle)
          map.addOverlay(mapmask)
        }
        if (polylineStyle) {
          let polylin = new window.BMapGL.Polyline(ptArr, polylineStyle);
          map.addOverlay(polylin)
        }
        if (polygonStyle) {
          let polygon = new window.BMapGL.Polygon(ptArr, polygonStyle);
          map.addOverlay(polygon)
        }
      }
      resolve(path)
    })
  })
}

export function getPointIcon (type) {
  return new window.BMapGL.Icon('/static/map/point-' + type + '.png', new window.BMapGL.Size(25, 25))
}

export function getCurrentLocation () {
  return new Promise(resolve => {
    console.log('get by', sysEnv.address)
    if (sysEnv.address) {
      new window.BMapGL.Geocoder().getPoint(sysEnv.address, point => {
        if (point) {
          resolve(point)
        } else {
          new window.BMapGL.LocalCity().get(local => resolve(local.center))
        }
      })
    } else {
      new window.BMapGL.LocalCity().get(local => resolve(local.center))
    }
  })
}
